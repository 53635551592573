/**
 * Internal dependencies
 */
import config from "../../config.json";

export const wrap = (el, className = "wrapper") => {
	const wrapper = document.createElement("div");
	wrapper.classList.add(className);
	el.parentNode.insertBefore(wrapper, el);
	wrapper.appendChild(el);
};

export const getBlockClassName = () => {
	return config?.blockClassName;
};

export const hasArrows = () => {
	return config?.slider?.hasArrows;
};
