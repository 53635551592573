/**
 * External dependencies
 */
import KeenSlider from "keen-slider";

/**
 * Internal dependencies
 */
import { getBlockClassName, hasArrows, wrap } from "./utils";

(function () {
	let sliders = [];
	let isInitialized = false;

	window.addEventListener("DOMContentLoaded", () => {
		if (!isInitialized) {
			isInitialized = true;
			init();
		}
	});

	const init = () => {
		const els = document.querySelectorAll(`.${getBlockClassName()}`);

		els.forEach(setupSlider);
	};

	const setupSlider = (el) => {
		const className = getBlockClassName();

		const wrapper = el.querySelector(`.${className}__slider`);
		wrapper.classList.add("keen-slider");

		const controls = createControls(`${className}__controls`);
		el.appendChild(controls);

		const cards = wrapper.querySelectorAll(":scope > *");
		cards.forEach((card) => wrap(card, "keen-slider__slide"));

		const els = {
			wrapper,
			slides: el.querySelectorAll(".keen-slider__slide"),
			prev: el.querySelector(`.${className}__controls__prev`),
			next: el.querySelector(`.${className}__controls__next`),
			dots: el.querySelector(`.${className}__controls__dots`),
		};

		/**
		 * If you change the spacing or slides per view here, be sure
		 * to change the unmounted styles in style.scss as well, to prevent CLS.
		 */
		const slider = createSlider(els, {
			loop: true,
			slides: {
				origin: "center",
				perView: 1.33333,
			},
			breakpoints: {
				"(min-width: 782px)": {
					// "md" breakpoint
				  	slides: {
						origin: "center",
						perView: 1.75,
					},
				},
				"(min-width: 1080px)": {
					// "lg" breakpoint
				  	slides: {
						origin: "center",
						perView: els.slides.length > 4 ? 3.375 : 2.75,
					},
				},
			},
		});

		if (!slider) {
			return false;
		}

		sliders.push(slider);
	};

	const createControls = (className = "controls") => {
		const controls = document.createElement("div");
		controls.classList.add(className);

		if (hasArrows()) {
			const arrows = document.createElement("div");
			arrows.classList.add(`${className}__arrows`);

			const prev = document.createElement("button");
			prev.classList.add(`${className}__prev`);

			const next = document.createElement("button");
			next.classList.add(`${className}__next`);

			arrows.appendChild(prev);
			arrows.appendChild(next);
			controls.appendChild(arrows);
		}

		return controls;
	};

	const createSlider = (els, options) => {
		const hasSlides = els.slides && els.slides.length > 0;

		if (!hasSlides) {
			return false;
		}

		const addArrowEvents = (instance) => {
			if (!hasArrows()) {
				return false;
			}

			els.prev.addEventListener("click", () => instance.prev());
			els.next.addEventListener("click", () => instance.next());
		};

		const setActiveSlides = (instance) => {
			const { rel: relativeSlide } = instance.track.details;

			els.slides.forEach((slide, index) => {
				slide.classList.toggle(
					"is-active",
					index === relativeSlide
				);
			});
		};

		return new KeenSlider(els.wrapper, {
			...options,
			created: (instance) => {
				addArrowEvents(instance);
				setActiveSlides(instance);
				els.wrapper.classList.add("is-mounted");
			},
			slideChanged: (instance) => {
				setActiveSlides(instance);
			},
		});
	};
})();
